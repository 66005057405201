p, .nav-wrapper li a {
	font-family: "Josefin Sans", sans-serif;
	font-size: 16px;
}

p a {
	color: #3e2723;
}

.brand-logo, h1, h2, h3 {
	font-family: "Playfair Display", serif;
}

nav .nav-wrapper {
	max-width: 1280px;
	margin: 0 auto;
}

nav .brand-logo {
	padding: 0 15px;
	display: inline-block;
}

nav .brand-logo p {
	display: inline-block;
	vertical-align: 0;
	margin: 0 0 0 1.5em;
	font-size: 12px;
}

@media only screen and (max-width: 992px) {
	nav .brand-logo {
		width: 100%;
		text-align: center;
	}

	footer img {
		display: none;
	}
}