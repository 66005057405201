.reportPreload {
	min-height: 95vh;
}

.reportText {
	font-family: "Josefin Sans", sans-serif;
	font-size: 16px;
}

.reportText a, .titleText a {
	color: #bf360c;
}

.reportText img {
	max-width: 100%;
}